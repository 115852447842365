import React from 'react';
import './sass/Footer.sass';
import 'remixicon/fonts/remixicon.css'

class Footer extends React.Component {
  render() {
    return(
      <footer>
    
    <div className="ContentMain d-flex justify-content-center footerSocial">
    <div className="ContentBlock">

    <div className="row CGrid" >
        <div className="footerSocial">

        <p>feel free to contact me</p>
        
          <a href="https://t.me/diverkoo" target="_blank" className="social">
            <i className="ri-telegram-fill ri-2x"></i>
          </a>

            &nbsp;&nbsp;&nbsp;

          <a href="https://www.instagram.com/diverkoo/" target="_blank" className="social">
            <i className="ri-instagram-fill ri-2x"></i>
          </a>

        </div>
        
        <div className="footerVideoProduction">
          <span>код написан Vincent Mendoza<br/>2011-2023</span>
        </div>

      </div>
        
      </div>
      </div>
      </footer>
    );
  }
}

export default Footer;

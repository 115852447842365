import React from "react";

class VideoOverlay extends React.Component {
  constructor(props) {
    super(props);
    console.count();
  }
  // vimeo.com/369793523
  render() {
    console.log("this.props.videoId = " + this.props.videoID);
    let iframe = "";
    switch (this.props.service) {
      case "vimeo":
        iframe = (
          <iframe
            src={"https://player.vimeo.com/video/" + this.props.videoID}
            width="100%"
            height="480"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        );
        break;
      default:
        iframe = (
          <iframe
            className="youtubePlayer"
            width="100%"
            height="480"
            src={"https://www.youtube.com/embed/" + this.props.videoID}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
    }
    return (
      <div className="overlay videoShower" onClick={this.props.handleClick}>
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-12 col-lg-12 d-flex youtube-position ShowerBlockyoutube">
            {/*<iframe className="youtubePlayer" width="100%" height="480" src={"https://www.youtube.com/embed/"+this.props.videoID} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>*/}
            {iframe}
          </div>
          {/*} <div className="col-12 col-lg-4 row d-flex videotext-position">
              <VideoOverlayText id={this.props.id}/>
    </div>*/}
        </div>
      </div>
    );
  }
}

export default VideoOverlay;

import React from "react";
import "./sass/Content.sass";
import Video from "./Video";
import Sarafanov from "./reels/Sarafanov";
import GIS from "./reels/GIS";
import Rhemorha from "./reels/Rhemorha";
import Whoosh from "./reels/Whoosh";
import Girand from "./reels/Girand";
import BeLoved from "./reels/BeLoved";
import Bodypositive from "./reels/Bodypositive";
import Solitude from "./reels/Solitude";
import { isTablet, isMobile } from "react-device-detect";

let case0 = new Sarafanov();
let case1 = new GIS();
let case2 = new Rhemorha();
let case3 = new Whoosh();
let case4 = new Girand();
let case5 = new BeLoved();
let case6 = new Bodypositive();
let case7 = new Solitude();

const prevVideos = [
  require("./video/" + case0.GetName() + "/1.mp4"), /* 0 case0*/
  require("./video/" + case0.GetName() + "/2.mp4"), /* 1 case0*/
  require("./video/" + case0.GetName() + "/3.mp4"), /* 2 case0*/

  require("./video/" + case1.GetName() + "/1.mp4"), /* 3 case1*/
  require("./video/" + case1.GetName() + "/2.mp4"), /* 4 case1*/
  require("./video/" + case1.GetName() + "/3.mp4"), /* 5 case1*/
  require("./video/" + case1.GetName() + "/4.mp4"), /* 6 case1*/
  require("./video/" + case1.GetName() + "/5.mp4"), /* 7 case1*/
  require("./video/" + case1.GetName() + "/6.mp4"), /* 8 case1*/
  require("./video/" + case1.GetName() + "/7.mp4"), /* 9 case1*/

  require("./video/" + case2.GetName() + "/1.mp4"), /* 10 case2*/
  require("./video/" + case2.GetName() + "/2.mp4"), /* 11 case2*/
  require("./video/" + case2.GetName() + "/3.mp4"), /* 12 case2*/
  require("./video/" + case2.GetName() + "/4.mp4"), /* 13 case2*/
  require("./video/" + case2.GetName() + "/5.mp4"), /* 14 case2*/
  require("./video/" + case2.GetName() + "/6.mp4"), /* 15 case2*/
  require("./video/" + case2.GetName() + "/7.mp4"), /* 16 case2*/
  require("./video/" + case2.GetName() + "/8.mp4"), /* 17 case2*/
  require("./video/" + case2.GetName() + "/9.mp4"), /* 18 case2*/

  require("./video/" + case3.GetName() + "/1.mp4"), /* 19 case3*/
  require("./video/" + case3.GetName() + "/2.mp4"), /* 20 case3*/
  require("./video/" + case3.GetName() + "/3.mp4"), /* 21 case3*/
  require("./video/" + case3.GetName() + "/4.mp4"), /* 22 case3*/
  require("./video/" + case3.GetName() + "/5.mp4"), /* 23 case3*/
  require("./video/" + case3.GetName() + "/6.mp4"), /* 24 case3*/
  require("./video/" + case3.GetName() + "/7.mp4"), /* 25 case3*/
  require("./video/" + case3.GetName() + "/8.mp4"), /* 26 case3*/
  require("./video/" + case3.GetName() + "/9.mp4"), /* 27 case3*/

  require("./video/" + case4.GetName() + "/1.mp4"), /* 28 case4*/
  require("./video/" + case4.GetName() + "/2.mp4"), /* 29 case4*/
  require("./video/" + case4.GetName() + "/3.mp4"), /* 30 case4*/
  require("./video/" + case4.GetName() + "/4.mp4"), /* 31 case4*/
  require("./video/" + case4.GetName() + "/5.mp4"), /* 32 case4*/
  require("./video/" + case4.GetName() + "/6.mp4"), /* 33 case4*/
  require("./video/" + case4.GetName() + "/7.mp4"), /* 34 case4*/
  require("./video/" + case4.GetName() + "/8.mp4"), /* 35 case4*/

  require("./video/" + case5.GetName() + "/1.mp4"), /* 36 case5*/
  require("./video/" + case5.GetName() + "/2.mp4"), /* 37 case5*/
  require("./video/" + case5.GetName() + "/3.mp4"), /* 38 case5*/
  require("./video/" + case5.GetName() + "/4.mp4"), /* 39 case5*/
  require("./video/" + case5.GetName() + "/5.mp4"), /* 40 case5*/

  require("./video/" + case6.GetName() + "/1.mp4"), /* 41 case6*/
  require("./video/" + case6.GetName() + "/2.mp4"), /* 42 case6*/
  require("./video/" + case6.GetName() + "/3.mp4"), /* 43 case6*/

  require("./video/" + case7.GetName() + "/1.mp4"), /* 44 case7*/
  require("./video/" + case7.GetName() + "/2.mp4"), /* 45 case7*/
  require("./video/" + case7.GetName() + "/3.mp4"), /* 46 case7*/
  require("./video/" + case7.GetName() + "/4.mp4"), /* 47 case7*/
  require("./video/" + case7.GetName() + "/5.mp4"), /* 48 case7*/
  require("./video/" + case7.GetName() + "/6.mp4"), /* 49 case7*/
  require("./video/" + case7.GetName() + "/7.mp4"), /* 50 case7*/

];

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevVideos: new Array(3),
    };
  }

  dividerInfo(info) {
    return <div>{info}</div>;
  }

  render() {
    let classGrid = " row CGrid";
    let classRow2 = " col-sm-6 col-lg-6";
    let classRow3 = " col-sm-4 col-lg-4";
    let classRow4 = " col-sm-3 col-lg-3";

    let works = "works";

    // PC
    let tut_img_a = <img src="./img/point.png" width="70px"></img>;
    let tut_img_b = <img src="./img/click.png" width="80px"></img>;
    if ((isMobile || isTablet) === true) {
      tut_img_a = <img src="./img/touch.png" width="80px"></img>;
      tut_img_b = "";
    }

    console.log(this.state);
    console.log(this.state.prevVideos[1]);

    return (
      <>
        <span class="anchor intro" id="intro"></span>
        <div class="section"></div>

        {/*###CASE0###SARAFANOV###*/}

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case0.GetIntro())}

              <div className="row col-md-12 col-lg-12">
                <div>{tut_img_a}</div>
              </div>

              <Video
                class={classRow3}
                img={require("./img/" + case0.GetName() + "/1.jpg")}

                prevVideo={prevVideos[0]}
                service="vimeo"
                videoID={case0.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[1] = prevVideos[1];
                  this.setState({ prevVideos: arr });
                }}
              />
              <Video
                class={classRow3}
                img={require("./img/" + case0.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[1]}
                service="vimeo"
                videoID={case0.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[2] = prevVideos[2];
                  this.setState({ prevVideos: arr });
                }}
              />
              <Video
                class={classRow3}
                img={require("./img/" + case0.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[2]}
                service="vimeo"
                videoID={case0.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[3] = prevVideos[3];
                  this.setState({ prevVideos: arr });
                }}
              />

              <div className="row CGrid col-6">
                {this.dividerInfo(case0.GetOutro())}
              </div>
              <div className="row CGrid col-6">
                <div>{tut_img_b}</div>
              </div>
            </div>
          </div>
        </div>

        {/*###CASE1###2GIS###*/}

        <span class="anchor" id="case1"></span>
        <div class="section"></div>

        <hr class="divider"></hr>

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case1.GetIntro())}

              <Video
                class={classRow2}
                img={require("./img/" + case1.GetName() + "/1.jpg")}

                prevVideo={this.state.prevVideos[3]}
                videoID={case1.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[4] = prevVideos[4];
                  this.setState({ prevVideos: arr });
                }}
              />
           
              <Video
                class={classRow2}
                img={require("./img/" + case1.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[4]}
                videoID={case1.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[5] = prevVideos[5];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case1.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[5]}
                videoID={case1.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[6] = prevVideos[6];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case1.GetName() + "/4.jpg")}

                prevVideo={this.state.prevVideos[6]}
                videoID={case1.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[7] = prevVideos[7];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case1.GetName() + "/5.jpg")}

                prevVideo={this.state.prevVideos[7]}
                videoID={case1.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[8] = prevVideos[8];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case1.GetName() + "/6.jpg")}

                prevVideo={this.state.prevVideos[8]}
                videoID={case1.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[9] = prevVideos[9];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case1.GetName() + "/7.jpg")}

                prevVideo={this.state.prevVideos[9]}
                videoID={case1.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[10] = prevVideos[10];
                  this.setState({ prevVideos: arr });
                }}
              />

              {this.dividerInfo(case1.GetOutro())}
            </div>
          </div>
        </div>

        {/*###CASE2###RHEMORHA###*/}

        <span class="anchor" id="case2"></span>
        <div class="section"></div>

        <hr class="divider"></hr>

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case2.GetIntro())}

              <Video
                class={classRow2}
                img={require("./img/" + case2.GetName() + "/1.jpg")}

                prevVideo={this.state.prevVideos[10]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[11] = prevVideos[11];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case2.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[11]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[12] = prevVideos[12];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case2.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[12]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[13] = prevVideos[13];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case2.GetName() + "/4.jpg")}

                prevVideo={this.state.prevVideos[13]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[14] = prevVideos[14];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case2.GetName() + "/5.jpg")}

                prevVideo={this.state.prevVideos[14]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[15] = prevVideos[15];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case2.GetName() + "/6.jpg")}

                prevVideo={this.state.prevVideos[15]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[16] = prevVideos[16];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case2.GetName() + "/7.jpg")}

                prevVideo={this.state.prevVideos[16]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[17] = prevVideos[17];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case2.GetName() + "/8.jpg")}

                prevVideo={this.state.prevVideos[17]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[18] = prevVideos[18];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case2.GetName() + "/9.jpg")}

                prevVideo={this.state.prevVideos[18]}
                service="vimeo"
                videoID={case2.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[19] = prevVideos[19];
                  this.setState({ prevVideos: arr });
                }}
              />

              {this.dividerInfo(case2.GetOutro())}
            </div>
          </div>
        </div>

        {/*###CASE3###WHOOSH###*/}

        <span class="anchor" id="case3"></span>
        <div class="section"></div>

        <hr class="divider"></hr>

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case3.GetIntro())}

              <Video
                class={classRow2}
                img={require("./img/" + case3.GetName() + "/1.jpg")}

                prevVideo={this.state.prevVideos[19]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[20] = prevVideos[20];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case3.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[20]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[21] = prevVideos[21];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case3.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[21]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[22] = prevVideos[22];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case3.GetName() + "/4.jpg")}

                prevVideo={this.state.prevVideos[22]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[23] = prevVideos[23];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case3.GetName() + "/5.jpg")}

                prevVideo={this.state.prevVideos[23]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[24] = prevVideos[24];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow4}
                img={require("./img/" + case3.GetName() + "/6.jpg")}

                prevVideo={this.state.prevVideos[24]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[25] = prevVideos[25];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow4}
                img={require("./img/" + case3.GetName() + "/7.jpg")}

                prevVideo={this.state.prevVideos[25]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[26] = prevVideos[26];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow4}
                img={require("./img/" + case3.GetName() + "/8.jpg")}

                prevVideo={this.state.prevVideos[26]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[27] = prevVideos[27];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow4}
                img={require("./img/" + case3.GetName() + "/9.jpg")}

                prevVideo={this.state.prevVideos[27]}
                videoID={case3.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[28] = prevVideos[28];
                  this.setState({ prevVideos: arr });
                }}
              />

              {this.dividerInfo(case3.GetOutro())}
            </div>
          </div>
        </div>

        {/*###CASE4###GIRAND###*/}

        <span class="anchor" id="case4"></span>
        <div class="section"></div>

        <hr class="divider"></hr>

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case4.GetIntro())}

              <Video
                class={classRow3}
                img={require("./img/" + case4.GetName() + "/1.jpg")}

                prevVideo={this.state.prevVideos[28]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[29] = prevVideos[29];
                  this.setState({ prevVideos: arr });
                }}
              />

               <Video
                class={classRow3}
                img={require("./img/" + case4.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[29]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[30] = prevVideos[30];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case4.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[30]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[31] = prevVideos[31];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case4.GetName() + "/4.jpg")}

                prevVideo={this.state.prevVideos[31]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[32] = prevVideos[32];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case4.GetName() + "/5.jpg")}

                prevVideo={this.state.prevVideos[32]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[33] = prevVideos[33];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case4.GetName() + "/6.jpg")}

                prevVideo={this.state.prevVideos[33]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[34] = prevVideos[34];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case4.GetName() + "/7.jpg")}

                prevVideo={this.state.prevVideos[34]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[35] = prevVideos[35];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case4.GetName() + "/8.jpg")}

                prevVideo={this.state.prevVideos[35]}
                videoID={case4.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[36] = prevVideos[36];
                  this.setState({ prevVideos: arr });
                }}
              />

              {this.dividerInfo(case4.GetOutro())}
            </div>
          </div>
        </div>

        {/*###CASE5###BELOVED###*/}

        <span class="anchor" id="case5"></span>
        <div class="section"></div>

        <hr class="divider"></hr>

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case5.GetIntro())}

              <Video
                class={classRow3}
                img={require("./img/" + case5.GetName() + "/1.jpg")}

                prevVideo={this.state.prevVideos[36]}
                service="vimeo"
                videoID={case5.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[37] = prevVideos[37];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case5.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[37]}
                service="vimeo"
                videoID={case5.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[38] = prevVideos[38];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case5.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[38]}
                videoID={case5.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[39] = prevVideos[39];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case5.GetName() + "/4.jpg")}

                prevVideo={this.state.prevVideos[39]}
                service="vimeo"
                videoID={case5.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[40] = prevVideos[40];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case5.GetName() + "/5.jpg")}

                prevVideo={this.state.prevVideos[40]}
                videoID={case5.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[41] = prevVideos[41];
                  this.setState({ prevVideos: arr });
                }}
              />

              {this.dividerInfo(case5.GetOutro())}
            </div>
          </div>
        </div>

        {/*###CASE6###BODYPOSITIVE###*/}

        <span class="anchor" id="case6"></span>
        <div class="section"></div>

        <hr class="divider"></hr>

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case6.GetIntro())}

              <Video
                class={classRow3}
                img={require("./img/" + case6.GetName() + "/1.jpg")}

                prevVideo={this.state.prevVideos[41]}
                service="vimeo"
                videoID={case6.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[42] = prevVideos[42];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case6.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[42]}
                service="vimeo"
                videoID={case6.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[43] = prevVideos[43];
                  this.setState({ prevVideos: arr });
                }}
              />

<Video
                class={classRow3}
                img={require("./img/" + case6.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[43]}
                service="vimeo"
                videoID={case6.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[44] = prevVideos[44];
                  this.setState({ prevVideos: arr });
                }}
              />

              {this.dividerInfo(case6.GetOutro())}
            </div>
          </div>
        </div>

        {/*###CASE7###SOLITUDE###*/}

        <span class="anchor" id="case7"></span>
        <div class="section"></div>

        <hr class="divider"></hr>

        <div className="ContentMain d-flex justify-content-center">
          <div className="ContentBlock">
            <div className={classGrid}>
              {this.dividerInfo(case7.GetIntro())}

              <Video
                class={classRow2}
                img={require("./img/" + case7.GetName() + "/1.jpg")}

                prevVideo={this.state.prevVideos[44]}
                service="vimeo"
                videoID={case7.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[45] = prevVideos[45];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case7.GetName() + "/2.jpg")}

                prevVideo={this.state.prevVideos[45]}
                service="vimeo"
                videoID={case7.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[46] = prevVideos[46];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case7.GetName() + "/3.jpg")}

                prevVideo={this.state.prevVideos[46]}
                service="vimeo"
                videoID={case7.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[47] = prevVideos[47];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow3}
                img={require("./img/" + case7.GetName() + "/4.jpg")}

                prevVideo={this.state.prevVideos[47]}
                service="vimeo"
                videoID={case7.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[48] = prevVideos[48];
                  this.setState({ prevVideos: arr });
                }}
              />

               <Video
                class={classRow3}
                img={require("./img/" + case7.GetName() + "/5.jpg")}

                prevVideo={this.state.prevVideos[48]}
                service="vimeo"
                videoID={case7.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[49] = prevVideos[49];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case7.GetName() + "/6.jpg")}

                prevVideo={this.state.prevVideos[49]}
                service="vimeo"
                videoID={case7.GetID()}
                onFinishLoad={() => {
                  const arr = this.state.prevVideos.slice(0);
                  arr[50] = prevVideos[50];
                  this.setState({ prevVideos: arr });
                }}
              />

              <Video
                class={classRow2}
                img={require("./img/" + case7.GetName() + "/7.jpg")}

                prevVideo={this.state.prevVideos[50]}
                service="vimeo"
                videoID={case7.GetID()}
                onFinishLoad={() => {
                }}
              />

              {this.dividerInfo(case7.GetOutro())}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Content;

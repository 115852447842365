import React from 'react';

class Rhemorha {

    GetIntro() {
        return(<>
            
            <h1> RHEMORHA. MR_OFFICER</h1>

            {/*<a href="https://vk.com/rhemorhaband" target="_blank">
                <img src="./img/logos/rhemorha.png" width="130px"></img>
        </a> */}
      
            <h2>(музыкальный клип)</h2>
            
            <p> 
                Триллер. Банда панков-хакеров крадет преуспевающего бизнесмена для странного ритуала на заброшенном складе. Обезумевшие реднеки играют метал и собираются принести жертву.
            </p>

        </>);
    }

    GetOutro() {
        return(<>
            
            <p> режиссер / продюсер АЛЕКСАНДР САРАФАНОВ для &nbsp;
                
                <a href="http://www.stkino.com/" target="_blank">
                    <img src="./img/logos/stkino-black.png" width="90px"></img>
                </a>
            
                &nbsp;&nbsp;x&nbsp;&nbsp;
            
                <a href="https://www.maxproduction.com/" target="_blank">
                    <img src="./img/logos/maxprod-black.png" width="50px"></img>
                </a>
                </p>
                <p>
                генеральный продюсер МАКС КАРПЫЛЕВ оператор-постановщик / колорист ВЯЧЕСЛАВ БАБИЙЧУК
                художник-постановщик ЕГОР ДУБУК стилист САША ЗНАХАРЕНКО гримёр СОНЯ ДОЛОНИНА
                техник КИРИЛЛ СМОЛЯНИНОВ графический монтаж АЛЕКСАНДР САРАФАНОВ дизайн и титры ЕВГЕНИЙ ГАВРИЛОВ

            </p>

            <a name="anchor3"></a>
      
            <p>2021</p>
            
        </>);
    }

    GetName() {
        return("rhemorha");
    }

    GetID() {
        return("698304803");
    }

}

export default Rhemorha;
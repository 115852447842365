import React from "react";
import "./sass/Video.sass";
import VideoCard from "./VideoCard/VideoCard";

class Video extends React.Component {
  render() {
    return (
      <VideoCard
        id={this.props.id}
        class={this.props.class}
        videoID={this.props.videoID}
        prevVideo={this.props.prevVideo}
        clientLogo={this.props.clientLogo}
        img={this.props.img}
        textPrevTag={this.props.textPrevTag}
        textPrevTitle={this.props.textPrevTitle}
        service={this.props.service}
        closePrevElement={() => this.props.closePrevElement()}
        initOpenElement={(val) => this.props.initOpenElement(val)}
        onFinishLoad={() => this.props.onFinishLoad()}
      />
    );
  }
}

export default Video;

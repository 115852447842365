import React from 'react';
import './sass/Header.sass';
import ImgLogo from './img/logo_01_726x135.png';

class Header extends React.Component {
  render() {
    var $_GET=[];
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(a,name,value){$_GET[name]=value;});
    let arr_active = ["active","",""];
    if($_GET['page']=='clients') {
      arr_active[0]="";
      arr_active[2]="active";
    }
    return(
      <div>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <div className="container-fluid">

            
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-center" id="navbarCollapse">
              <ul className="navbar-nav text-center">
                <li className={"nav-item " + arr_active[0]}><a href="#intro" className="nav-link">начало</a></li>
                <li className={"nav-item " + arr_active[1]}><a href="#case1" className="nav-link">#1</a></li>
                <li className={"nav-item " + arr_active[2]}><a href="#case2" className="nav-link">#2</a></li>
                <li className={"nav-item " + arr_active[3]}><a href="#case3" className="nav-link">#3</a></li>
                <li className={"nav-item " + arr_active[4]}><a href="#case4" className="nav-link">#4</a></li>
                <li className={"nav-item " + arr_active[5]}><a href="#case5" className="nav-link">#5</a></li>
                <li className={"nav-item " + arr_active[6]}><a href="#case6" className="nav-link">#6</a></li>
                <li className={"nav-item " + arr_active[7]}><a href="#case7" className="nav-link">#7</a></li>
              </ul>

              {/*<div className="container-fluid social-block">
                <a href="https://www.instagram.com/studiakino/" target="_blank" className="social"><i className="fab fa-instagram"></i></a>
                <a href="https://www.youtube.com/user/studiakino" target="_blank" className="social"><i className="fab fa-youtube"></i></a>
              </div> */}

            </div>
          </div>
        </nav>
      </div>
    );
    //<div className="header__placeholder"></div>
  }
}

export default Header;

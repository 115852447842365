import React from 'react';

class GIS {

    GetIntro() {
        return(<>

            <a href="https://www.2gis.ru/" target="_blank">
                <img className="logo-top" src="./img/logos/2gis-black.png" width="130px"></img>
            </a>

            <h1>ПРЕЗЕНТАЦИЯ ОСЕНЬ '21

            

            </h1>

            <h2> (корпоративное кино)</h2>
      
      
            <p>
                2ГИС и Женя Чебатков рассказывают про масштабное обновление навигатора, фокус на работу в столицах, как используются карты в сервисах Ситимобила и Сбердевайсов и успехах интеграции в экосистему Сбера.
            </p>

        </>);
    }

    GetOutro() {
        return(<>

            <p> режиссер / продюсер АЛЕКСАНДР САРАФАНОВ для &nbsp;

                <a href="https://www.maxproduction.com/" target="_blank">
                    <img src="./img/logos/maxprod-black.png" width="70px" ></img>
                </a> 

            </p>
            <p>
      
                генеральный продюсер МАКС КАРПЫЛЕВ оператор-постановщик ИЛЬЯ МЕНЗАРАРЬ оператор стедикама КИРИЛЛ ТИМОФЕЕВ
                гаффер ЛЕВ РУБЦОВ монтаж ГЕННАДИЙ ПЕРОВ звукооператор АЛЕКСЕЙ БУТОВ
                техник КИРИЛЛ СМОЛЯНИНОВ стилист САША ЗНАХАРЕНКО визажист СОНЯ ДОЛОНИНА

            </p>

            <a name="anchor2"></a>
     
            <p>2021</p>
      
            </>);
    }

    GetName() {
        return("2gis");
    }

    GetID() {
        return("2s6kTYBgiQ0");
    }

}

export default GIS;
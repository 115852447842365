import React from "react";
import PreviewAnimation from "./PreviewAnimation";
import VideoOverlay from "./VideoOverlay";

class VideoCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      type: "SHOW_PREVIMG",
    };
  }

  closeOverlay() {
    this.handleClick();
    console.log("closeOverlay");
  }

  handleClick() {
    console.log("handleClick " + this.props.id);
    if (this.state.type == "SHOW_OVERLAY")
      this.setState({ type: "SHOW_PREVIMG" });
    else this.setState({ type: "SHOW_OVERLAY" });
  }

  render() {
    const type = this.state.type;
    return (
      <div className={this.props.class + " element"}>
        <PreviewAnimation
          textPrevTag={this.props.textPrevTag}
          textPrevTitle={this.props.textPrevTitle}
          prevVideo={this.props.prevVideo}
          clientLogo={this.props.clientLogo}
          poster={this.props.img}
          closePrevElement={() => this.props.closePrevElement()}
          initOpenElement={(val) => this.props.initOpenElement(val)}
          showOverlay={() => this.handleClick()}
          onFinishLoad={() => this.props.onFinishLoad()}
        />
        {type == "SHOW_OVERLAY" && (
          <VideoOverlay
            id={this.props.id}
            videoID={this.props.videoID}
            handleClick={() => this.closeOverlay()}
            service={this.props.service}
          />
        )}
      </div>
    );
  }
}

export default VideoCard;

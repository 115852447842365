import React from 'react';

class Solitude {

    GetIntro() {
        return(<>

            <h1>SOLITUDE</h1>

            <h2>(авторское видео)</h2>

            <p>Путешествие по пустым, практически безлюдным мирам.</p>

            <p>Работа, собранная из материалов снятых для разных проектов в период с 2016 по 2019 на DJI Phantom 4.</p>

            <a name="solitude"></a>

        </>);
    }

    GetOutro() {
        return(<>

            <p>автор и оператор коптера АЛЕКСАНДР САРАФАНОВ трек COUNTERPHASE исполнитель VAIKONEN</p>

            <p>2019</p>
            
        </>);
    }

    GetName() {
        return("solitude");
    }

    GetID() {
        return("369793523");
    }

}

export default Solitude;
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { isTablet, isMobile } from "react-device-detect";
import { Oval } from "react-loader-spinner";

const PreviewAnimation = (props) => {
  const [type, setType] = useState("first");
  const [load, setLoad] = useState(true);

  const vidRef = useRef(null);

  const onLoadedMetadata = async () => {
    setLoad(false);
    props.onFinishLoad();
  };

  const clientLogo = () => {
    let tag;
    if (props.clientLogo != undefined)
      tag = <img src={props.clientLogo} alt="" />;
    else tag = "";
    return tag;
  };

  const setOpen = () => {
    setType("open");
  };

  const setClose = () => {
    setType("close");
  };

  const handlerPlayVideo = () => {
    switch (type) {
      case "open":
        vidRef.current.play();
        break;
      case "close":
        vidRef.current.pause();
        break;
      default:
        break;
    }
  };

  const detectClassShow = () => {
    let showhide;
    switch (type) {
      case "open":
        showhide = "show";
        break;
      case "close":
        showhide = "hide";
        break;
      default:
        showhide = "hide";
    }
    return showhide;
  };

  const renderPCVersion = () => {
    handlerPlayVideo();
    return (
      <div
        class="VideoBlockParent"
        onMouseEnter={setOpen}
        onMouseLeave={setClose}
        onClick={props.showOverlay}
      >
        <div className="prevVideoContainer">
          <Oval
            height={40}
            width={40}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass="loader"
            visible={load}
            ariaLabel="oval-loading"
            secondaryColor="#111"
            strokeWidth={5}
            strokeWidthSecondary={5}
          />
          <video
            ref={vidRef}
            loop
            muted
            playsInline
            className="prevVideo show"
            poster={props.poster}
            preload="auto"
            onCanPlay={onLoadedMetadata}
          >
            {props.prevVideo && (
              <source src={props.prevVideo} type="video/mp4" />
            )}
          </video>
        </div>
        <div className={"VideoPreviewText " + detectClassShow()}>
          <span> {props.textPrevTag}</span>
          <br />
          <h1>{props.textPrevTitle}</h1>
          {clientLogo()}
        </div>
      </div>
    );
  };

  const mobileHandlerClick = () => {
    if (type === "open") {
      props.showOverlay();
      return;
    }
    setOpen();
    props.closePrevElement();
    // props.initOpenElement(this);
  };

  const renderMobileVersion = () => {
    handlerPlayVideo();
    return (
      <div class="VideoBlockParent" onClick={mobileHandlerClick}>
        <video
          ref={vidRef}
          loop
          muted
          playsInline
          className="prevVideo show"
          poster={props.poster}
          preload="auto"
        >
          <source src={props.prevVideo} type="video/mp4" />
        </video>
      </div>
    );
  };

  let mobileDetecter = isMobile || isTablet;
  if (mobileDetecter === true) return renderMobileVersion();
  else return renderPCVersion();
};

export default PreviewAnimation;

import React from 'react';

class Girand {

    GetIntro() {
        return(<>


           
            
            <h1>  GIRAND: IN THE LOST CITY
                <a href="https://inlostcity.com" target="_blank">
                    <img className="logo-right" src="./img/logos/girand-black.png" width="100px" ></img>
                </a> 
            </h1>

            <h2 className="text-top">(игровой синематик)</h2>

            <p> Промо-синематик для игры Girand, которая находится на этапе разработки. Cоздан на Unreal Engine 5.  </p>

        </>);
    }

    GetOutro() {
        return(<>
            
                <p>
                    
                    режиссер / сценарист АЛЕКСАНДР САРАФАНОВ для &nbsp;

                    <a href="http://www.stkino.com/" target="_blank">
                        <img src="./img/logos/stkino-black.png" width="120px"></img>
                    </a>

                </p>
                <p>    
                    executive producer SERGEY FOMINYKH unreal engine generalist DMITRY GRIBANOV vfx artist / producer VALERY GORBUNOV
                    chief 3d animator IGOR RYKOV tech / vfx artist SERGEY MAKSIMOV animator BORIS VINITSKIY
                    3d artist PETR VOROBEY level designer ARTEM KALININ music producer ILYA BOLOTNIKOV

                </p>
            
                <p>2022</p>
            
        </>);
    }

    GetName() {
        return("girand");
    }

    GetID() {
        return("DmYjI6rCYqg");
    }

}

export default Girand;
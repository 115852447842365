import React from 'react';

class Bodypositive {

    GetIntro() {
        return(<>
            
            <h1> БОДИПОЗИТИВ </h1>

            <h2>(авторское видео)</h2>

            

            <p>
                Микробюджетный рекламный ролик для новосибирской сети пекарен, которая сочла итоговый ролик слишком... эээ... "прогрессивным" для запуска рекламной кампании.
            </p>
            <p>
                Нейминг и лого клиента зацензурилось, а работа ушла на вимео.
            </p>

        </>);
    }

    GetOutro() {
        return(<>
            
            <p> режиссер / сценарист АЛЕКСАНДР САРАФАНОВ </p>
               
            <p> продюсер ИГОРЬ ОЛЕШКО линейный продюсер ЭРИКА КИМ оператор-постановщик
                ДАНИЛА ШУЛЯК гаффер ВЛАДИСЛАВ ГРЕБЕНЩИКОВ </p>
            
            <p>2021</p>
            
        </>);
    }

    GetName() {
        return("bodypositive");
    }

    GetID() {
        return("769503297");
    }

}

export default Bodypositive;
import React from 'react';

class BeLoved {

    GetIntro() {
        return(<>
            
            <h1> BE LOVED. KOREAN COSMETICS 

                <a href="https://nlstar.com" target="_blank">
                    <img src="./img/logos/nl.png" width="80px" className="logo-right"></img>
                </a>

            </h1>

            <h2  className="text-top">(рекламное видео)</h2>

            <p> Промо-ролик к запуску новой корейской линейки уходовой косметики Be Loved компании NL.</p>

        </>);
    }

    GetOutro() {
        return(<>
            
            <p>
                
                режиссер АЛЕКСАНДР САРАФАНОВ для &nbsp;
                
                <a href="http://www.stkino.com/" target="_blank">
                    <img src="./img/logos/stkino-black.png" width="90px"></img>
                </a>
            </p>
                
                
            <p>
                генеральный продюсер АЛЕКСАНДР ПРОШУНИН оператор-постановщик ИЛЬЯ МЕНЗАРАРЬ
                визажист СОНЯ ДОЛОНИНА гаффер ЛЕВ РУБЦОВ линейный продюсер АЛЕКСАНДР ЗАПРУДИН монтаж АЛЕКСАНДР САРАФАНОВ
                
            </p>

            <p>2021</p>
            
        </>);
    }

    GetName() {
        return("beloved");
    }

    GetID() {
        return("512712613");
    }

}

export default BeLoved;
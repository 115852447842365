import React from 'react';

class Sarafanov {

    GetIntro() {
        return(this.GetIntroC());
    }

    GetIntroC() {
        return(<>

            
            <h1>АЛЕКСАНДР САРАФАНОВ</h1>

            <h2>(режиссер / продюсер / сценарист)</h2>

        </>);
    }

    GetIntroB() {
        return(<>
            
            <img src="./img/sarafanov/v2circle.png" width="150px"></img>
            
            <h1>АЛЕКСАНДР САРАФАНОВ</h1>

            <p class="color">РЕЖИССЕР / ПРОДЮСЕР / СЦЕНАРИСТ</p>

            <hr class="new"></hr>

            <p>I have come here to chew bubblegum and shoot films... and I'm all out of bubblegum.</p>

        </>);
    }

    GetIntroA() {
        return(<><div className="Banner">
          <img src="./img/sarafanov/v2circle.png" alt=""></img>
          <span>
            
            АЛЕКСАНДР САРАФАНОВ
            
            <span className="Subtitle">РЕЖИССЕР / ПРОДЮСЕР / СЦЕНАРИСТ</span>
          
          </span>
        </div>
        
        
        <hr class="new"></hr>

        <q>I have come here to chew bubblegum and shoot films... and I'm all out of bubblegum.</q>
        
        </>);
    }

    GetOutro() {
        return(<>
                
            <p>feel free to contact me</p>
        
            <a href="https://t.me/diverkoo" target="_blank" className="social-black">
                <i className="ri-telegram-fill ri-xl"></i>
            </a>

            <a href="https://www.instagram.com/diverkoo/" target="_blank" className="social-black">
                <i className="ri-instagram-fill ri-xl"></i>
            </a>
            
        </>);
    }

    GetName() {
        return("sarafanov");
    }

    GetID() {
        return("515111527");
    }

}

export default Sarafanov;
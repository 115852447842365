import React from 'react';

import './sass/App.sass';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

function App() {
/*test*/
  return (
    <div>
      <Header />
        <Content />
      <Footer />
    </div>
  );
}

export default App;
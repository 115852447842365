import React from 'react';

class Whoosh {

    GetIntro() {
        return(<>
            

            <a href="https://whoosh-bike.ru" target="_blank">
                <img src="./img/logos/whoosh-black.png" width="200px" className="logo-top"></img>
            </a>
            <h1>УДОБСТВО, ТЕХНОЛОГИИ И ДРАЙВ </h1>
            <h2>(имиджевое кино)</h2>

            
    
            

          

            <p>Whoosh помогает людям в городах быть мобильнее и делать это весело, безопасно и удобно. А мы помогли им рассказать про это!</p>

        </>);
    }

    GetOutro() {
        return(<>
            
            <p>
                режиссер / сценарист АЛЕКСАНДР САРАФАНОВ для &nbsp;
            
                <a href="https://geliomedia.ru" target="_blank">
                    <img src="./img/logos/gelio-black.png"></img>
                </a>
            </p>
            <p>    
            
                генеральный продюсер ДМИТРИЙ ЕМЕЛЬЯНОВ продюсер ВЛАДИСЛАВ КОЛЕСНИКОВ
                оператор-постановщик СЕРГЕЙ ЧУЕВ гаффер СТАНИСЛАВ ШКЛЯРУК линейный продюсер АНДРЕЙ КАЗАКОВ
                монтаж ГЕННАДИЙ ПЕРОВ колорист ВЯЧЕСЛАВ ЗВЫКОВ графика ВЛАДИМИР ПОРХУНОВ

            </p>

            <a name="whoosh"></a>
            
            <p>2022</p>
            
        </>);
    }

    GetName() {
        return("whoosh");
    }

    GetID() {
        return("qUI13ywULkw");
    }

}

export default Whoosh;